import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "LoginIndex",
    component: () => import(/* webpackChunkName: "login" */ "@/views/login/index.vue"),
  },
  {
    path: "/",
    name: "Main",
    component: () => import(/* webpackChunkName: "Main" */ "@/components/Layout/footerLayout.vue"),
    children: [
      {
        path: "/",
        name: "MainIndex",
        component: () => import(/* webpackChunkName: "MainIndex" */ "@/views/main/index.vue"),
      },
      {
        path: "/mypage/pet-edit/:id",
        name: "PetEdit",
        meta: "",
        component: () => import(/* webpackChunkName: "PetEdit" */ "@/views/mypage/pet-edit.vue"),
      },
      {
        path: "/mypage/mypage",
        name: "MyPageIndex",
        meta: "",
        component: () => import(/* webpackChunkName: "MyPageIndex" */ "@/views/mypage/mypage.vue"),
      },
      {
        path: "/mypage/password_update",
        name: "PassWordUpdate",
        meta: "",
        component: () => import(/* webpackChunkName: "PassWordUpdate" */ "@/views/mypage/password.vue"),
      },
    ],
  },
  {
    path: "/login/register",
    component: () => import(/* webpackChunkName: "register_parent" */ "@/components/Layout/registerLayout.vue"),
    children: [
      {
        path: "/login/register",
        name: "Register",
        component: () => import(/* webpackChunkName: "Register" */ "@/views/login/register.vue"),
      },
      {
        path: "/login/register3",
        name: "Register3",
        component: () => import(/* webpackChunkName: "Register3" */ "@/views/login/register3.vue"),
      },
      {
        path: "/findID",
        name: "FindID",
        component: () => import(/* webpackChunkName: "FindID" */ "@/views/login/find_id.vue"),
      },
      {
        path: "/findPW",
        name: "FindPW",
        component: () => import(/* webpackChunkName: "FindPW" */ "@/views/login/find_pw.vue"),
      },
    ],
  },
  {
    path: "/login/register2",
    name: "Register2",
    component: () => import(/* webpackChunkName: "register2_parent" */ "@/views/login/register2.vue"),
  },
  {
    path: "/mypage",
    name: "MyPage",
    component: () => import(/* webpackChunkName: "MyPage" */ "@/components/Layout/homeLayout.vue"),
    children: [
      {
        path: "/mypage/mypage-no-pet",
        name: "MyPageNoPet",
        component: () => import(/* webpackChunkName: "MyPageNoPet" */ "@/views/mypage/mypage-no-pet.vue"),
      },

      {
        path: "/mypage/faq",
        name: "FAQ",
        meta: {
          titleKey: 'mypage-menu5',
          logo: "faq-icon.png",
        },
        component: () => import(/* webpackChunkName: "FAQ" */ "@/views/mypage/faq.vue"),
      },
      {
        path: "/mypage/notify-setting",
        name: "NotifySetting",
        meta: {
          titleKey: 'mypage-menu6_1',
          logo: "health-icon.png",
        },
        component: () => import(/* webpackChunkName: "NotifySetting" */ "@/views/mypage/notify-setting.vue"),
      },
      {
        path: "/mypage/diagnosis",
        name: "Diagnosis",
        meta: {
          titleKey: 'mypage-menu2_1',
          logo: "health-icon.png",
        },
        component: () => import(/* webpackChunkName: "Diagnosis" */ "@/views/mypage/diagnosis.vue"),
      },
      {
        path: "/mypage/medicalRecord",
        name: "MedicalRecord",
        meta: {
          titleKey: 'mypage-menu2_2',
          logo: "health-icon.png",
        },
        component: () => import(/* webpackChunkName: "MedicalRecord" */ "@/views/mypage/medicalRecord.vue"),
      },
      {
        path: "/mypage/openSource",
        name: "OpenSource",
        meta: {
          titleKey: 'mypage-menu6_2',
          logo: "faq-icon.png",
        },
        component: () => import(/* webpackChunkName: "OpenSource" */ "@/views/mypage/opensource.vue"),
      },
      {
        path: "/mypage/gateway",
        name: "GatewayDelete",
        meta: {
          titleKey: 'mypage-menu1_3',
          logo: "faq-icon.png",
        },
        component: () => import(/* webpackChunkName: "GatewayDelete" */ "@/views/mypage/gateway.vue"),
      },
    ],
  },
  {
    path: "/schedule",
    name: "Schedule",
    component: () => import(/* webpackChunkName: "Schedule" */ "@/components/Layout/homeLayout.vue"),
    children: [
      {
        path: "/",
        name: "ScheduleIndex",
        meta: {
          titleKey: 'mypage-menu4',
          logo: "schedule-icon.png",
        },
        component: () => import(/* webpackChunkName: "ScheduleIndex" */ "@/views/schedule/index.vue"),
      },
      {
        path: "/schedule/add_schedule",
        name: "AddSchedule",
        meta: {
          titleKey: 'mypage-menu4',
          logo: "schedule-icon.png",
        },
        component: () => import(/* webpackChunkName: "AddSchedule" */ "@/views/schedule/add_schedule.vue"),
      },
      {
        path: "/schedule/update_schedule",
        name: "UpdateSchedule",
        meta: {
          titleKey: 'mypage-menu4',
          logo: "schedule-icon.png",
        },
        component: () => import(/* webpackChunkName: "UpdateSchedule" */ "@/views/schedule/update_schedule.vue"),
      },
    ],
  },
  {
    path: "/hospital",
    name: "Hospital",
    component: () => import(/* webpackChunkName: "Hospital" */ "@/components/Layout/homeLayout.vue"),
    children: [
      {
        path: "/",
        name: "HospitalIndex",
        meta: {
          titleKey: 'footer_menu4',
          logo: "H_white.png",
        },
        component: () => import(/* webpackChunkName: "HospitalIndex" */ "@/views/hospital/index.vue"),
      },
      {
        path: "/reserve",
        name: "ReserveIndex",
        meta: {
          titleKey: 'mypage-menu3_1',
          logo: "H_white.png",
        },
        component: () => import(/* webpackChunkName: "ReserveIndex" */ "@/views/hospital/reservation.vue"),
      },
      {
        path: "/reserveHistory",
        name: "ReserveHistory",
        meta: {
          titleKey: 'mypage-menu3_2',
          logo: "H_white.png",
        },
        component: () => import(/* webpackChunkName: "ReserveHistory" */ "@/views/hospital/reserveHistory.vue"),
      },
      {
        path: "/reserveDetail",
        name: "ReserveDetail",
        meta: {
          titleKey: 'mypage-menu3_2',
          logo: "H_white.png",
        },
        component: () => import(/* webpackChunkName: "ReserveDetail" */ "@/views/hospital/reserveDetail.vue"),
      },
    ],
  },
  {
    path: "/self_check",
    name: "SelfCheck",
    component: () => import(/* webpackChunkName: "SelfCheck" */ "@/components/Layout/homeLayout.vue"),
    children: [
      {
        path: "/",
        name: "SelfCheckIndex",
        meta: {
          title: "건강 자가체크",
          logo: "H_white.png",
        },
        component: () => import(/* webpackChunkName: "SelfCheckIndex" */ "@/views/selfCheck/index.vue"),
      },
    ],
  },
  {
    path: "/ticket",
    name: "Ticket",
    component: () => import(/* webpackChunkName: "Ticket" */ "@/components/Layout/homeLayout.vue"),
    children: [
      {
        path: "/",
        name: "TicketIndex",
        meta: {
          title: "이용권 안내",
          logo: "schedule-icon.png",
        },
        component: () => import(/* webpackChunkName: "TicketIndex" */ "@/views/ticket/index.vue"),
      },
    ],
  },
  {
    path: "/point",
    name: "Point",
    component: () => import(/* webpackChunkName: "Point_parent" */ "@/components/Layout/homeLayout.vue"),
    children: [
      {
        path: "/",
        name: "Point",
        meta: {
          title: "포인트 관리",
          logo: "walk-icon.png",
        },
        component: () => import(/* webpackChunkName: "Point" */ "@/views/point/index.vue"),
      },
      {
        path: "/point/history",
        name: "PointHistory",
        meta: {
          title: "포인트 내역",
          logo: "walk-icon.png",
        },
        component: () => import(/* webpackChunkName: "PointHistory" */ "@/views/point/history.vue"),
      },
      {
        path: "/point/dailyCheck",
        name: "DailyCheck",
        meta: {
          title: "출석체크",
          logo: "walk-icon.png",
        },
        component: () => import(/* webpackChunkName: "DailyCheck" */ "@/views/point/dailyCheck.vue"),
      },
    ],
  },
  {
    path: "/walk",
    name: "Walk",
    component: () => import(/* webpackChunkName: "Walk" */ "@/components/Layout/homeLayout.vue"),
    children: [
      {
        path: "/",
        name: "WalkIndex",
        meta: {
          titleKey: 'footer_menu2',
          logo: "walk-icon.png",
        },
        component: () => import(/* webpackChunkName: "WalkIndex" */ "@/views/walk/index.vue"),
      },
      {
        path: "/walk/map",
        name: "walkmap",
        meta: {
          titleKey: 'footer_menu2',
          logo: "walk-icon.png",
        },
        component: () => import(/* webpackChunkName: "walkmap" */ "@/views/walk/walkmap.vue"),
      },
      {
        path: "/walk/hunt",
        name: "huntingmap",
        meta: {
          title: "사냥놀이",
          logo: "walk-icon.png",
        },
        component: () => import(/* webpackChunkName: "huntingmap" */ "@/views/walk/hunt.vue"),
      },
      {
        path: "/walk",
        name: "walkRank",
        meta: {
          titleKey: 'walk-rank__title',
          logo: "walk-icon.png",
        },
        component: () => import(/* webpackChunkName: "walkRank" */ "@/views/walk/WalkRank.vue"),
      },
      {
        path: "/walkHistory",
        name: "walkHistory",
        meta: {
          titleKey: 'walk-menu2',
          logo: "walk-icon.png",
        },
        component: () => import(/* webpackChunkName: "walkHistory" */ "@/views/walk/index.vue"),
      },
    ],
  },
  {
    path: "/walkShare",
    name: "WalkShare",
    component: () => import(/* webpackChunkName: "WalkShare" */ "@/views/walk/walkShare.vue"),
  },
  {
    path: "/health",
    name: "Health",
    component: () => import(/* webpackChunkName: "Health" */ "@/components/Layout/homeLayout.vue"),
    children: [
      {
        path: "/",
        name: "HealthIndex",
        meta: {
          titleKey: 'footer_menu3',
          logo: "health-icon.png",
        },
        component: () => import(/* webpackChunkName: "HealthIndex" */ "@/views/health/index.vue"),
      },
    ],
  },
  {
    path: "/shop",
    name: "ShopIndex",
    component: () => import(/* webpackChunkName: "ShopIndex_parent" */ "@/components/Layout/homeLayout.vue"),
    children: [
      {
        path: "/shop",
        name: "ShopIndex",
        meta: {
          titleKey: 'shop-title',
          logo: "health-icon.png",
        },
        component: () => import(/* webpackChunkName: "ShopIndex" */ "@/views/shop/index.vue"),
      },
    ],
  },
  {
    path: "/shop/detail",
    name: "ShopDetail",
    component: () => import(/* webpackChunkName: "ShopDetail_parent" */ "@/components/Layout/shopLayout.vue"),
    children: [
      {
        path: "/shop/detail",
        name: "ShopDetail",
        component: () => import(/* webpackChunkName: "ShopDetail" */ "@/views/shop/detail.vue"),
      },
      {
        path: "/shop/order",
        name: "ShopOrder",
        component: () => import(/* webpackChunkName: "ShopOrder" */ "@/views/shop/order.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!Vue.$cookies.get("accessToken");
  if (
    to.name !== "LoginIndex" &&
    to.name !== "LoginTest" &&
    to.name !== "Register" &&
    to.name !== "Register2" &&
    to.name !== "Register3" &&
    to.name !== "FindID" &&
    to.name !== "FindPW" &&
    to.name !== "WalkShare" &&
    !isAuthenticated
  ) {
    // alert("Please login again.");
    next({ name: "LoginIndex" });
  } else {
    next();
  }
});

export default router;
